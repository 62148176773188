// extracted by mini-css-extract-plugin
export const projects = "projects-module--projects--RoR2i";
export const project = "projects-module--project--1zdcJ";
export const projectHeader = "projects-module--project-header--2v42F";
export const projectImage = "projects-module--project-image--2dXAo";
export const active = "projects-module--active--3cJUA";
export const projectCard = "projects-module--project-card--12gAM";
export const offsetTallCard = "projects-module--offset-tall-card--2sz7C";
export const img = "projects-module--img--3c9-u";
export const control = "projects-module--control--3AygT";
export const bounceHorizontally = "projects-module--bounce-horizontally--32npo";